// ** Icons Imports
import { CloseRounded, InfoTwoTone, TimerTwoTone } from "@mui/icons-material";

// ** MUI Imports
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import DebugModeInfo from "./DebugModeInfo";
import { Line } from "../message";
import { convertMsToTime } from "./MainAnalytics";

// ** Types
type LineBreakInfoDialogProps = {
  lineInfoData: Line;
  dialogState: boolean;
  setDialogState: (value: React.SetStateAction<boolean>) => void;
};

const LineBreakInfoDialog = (props: LineBreakInfoDialogProps) => {
  // ** Props
  const { lineInfoData, dialogState, setDialogState } = props;

  // ** Functions
  const onClose = () => {
    setDialogState(false);
  };

  return (
    <Dialog
      fullWidth
      open={dialogState}
      maxWidth="md"
      scroll="body"
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          background: "rgba(255, 255, 255, 0.13) !important",
          backdropFilter: "blur(16px)",
        },
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 5 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.09)",
            p: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.09)",
              borderBottom: "3px solid rgba(255, 255, 255, 0.75)",
              p: 1,
              borderRadius: 1,
            }}
          >
            <TimerTwoTone
              sx={{
                color: "#f0f3f4",
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "1.3rem",
              color: "#f0f3f4",
              fontWeight: 600,
            }}
          >
            Mola Süresi Analizi
          </Typography>
          <IconButton
            sx={{
              color: "#f0f3f4",
            }}
            onClick={onClose}
            size="small"
            aria-label="settings"
          >
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3.5, px: { xs: 1, md: 10 } }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              background: "rgb(33, 36, 45)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
              gap: 2.5,
            }}
          >
            <InfoTwoTone
              sx={{
                fontSize: "1.75rem",
                color: "#d0d3d4",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontStyle: "italic",
                  color: "#f0f3f4",
                }}
              >
                Tüm mola süresi girişlerinin toplamı olan süre.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(240deg 5% 12%) 0%, hsl(240deg 11% 12%) 38%, hsl(240deg 18% 7%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label="LineBreaks" top={0} left={0} />
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    Molalar
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 2,
                  px: 0.8,
                  py: 0.4,
                  backgroundColor: "rgba(255, 255, 255, 0.13)",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.21)",
                  },
                  border: "1px solid rgb(255, 255, 255, 0.24)",
                }}
              >
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                  {lineInfoData.Breaks} dk
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label="LineBreaks1" top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Çay ve Yemek
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {lineInfoData.Breaks1} dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label="LineBreaks2" top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Diğer
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {lineInfoData.Breaks2} dk
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography
              sx={{
                color: "#f0f3f4",
                fontWeight: 700,
                fontSize: "1.15rem",
              }}
            >
              Hesaplamalar
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(225deg 15% 15%) 0%, hsl(229deg 25% 13%) 59%,hsl(234deg 36% 11%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderTop: "1px solid rgba(255, 139, 0, 0.48)",
              borderLeft: "1px solid rgba(255, 139, 0, 0.48)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label={"LineBreaksTime"} top={0} left={0} />
                  <Typography
                    sx={{
                      color: "rgb(243, 156, 18)",
                      fontSize: "1.25rem",
                      fontWeight: 700,
                    }}
                  >
                    Mola
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "rgb(243, 156, 18)",
                      fontWeight: 600,
                    }}
                  >
                    =
                  </Typography>
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      color: "#F6E2D7",
                    }}
                  >
                    Çay Akşam FM Süresi + Yemek Mola Süresi
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  color: "rgb(243, 156, 18)",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              >
                {(lineInfoData.BreaksTime / 60).toFixed(0)} dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineBreaks1"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Çay ve Yemek
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {lineInfoData.Breaks1} dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineBreaks2"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Diğer
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {lineInfoData.Breaks2} dk{" "}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LineBreakInfoDialog;
