// ** Icons Imports
import {
  CloseRounded,
  InfoTwoTone,
  TimerTwoTone,
} from "@mui/icons-material";

// ** MUI Imports
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import DebugModeInfo from "./DebugModeInfo";
import { Line } from "../message";

// ** Types
type LineRunInfoDialogProps = {
  lineInfoData: Line;
  dialogState: boolean;
  setDialogState: (value: React.SetStateAction<boolean>) => void;
};

const LineRunInfoDialog = (props: LineRunInfoDialogProps) => {
  // ** Props
  const { lineInfoData, dialogState, setDialogState } = props;

  // ** States
  const [expandedPaused, setExpandedPaused] = useState<boolean>(false);
  const [expandedPlannedPaused, setExpandedPlannedPaused] =
    useState<boolean>(false);
  const [expandedUnplannedPaused, setExpandedUnplannedPaused] =
    useState<boolean>(false);

  const returnErrorColor = (index: number) => {
    if (index < 2) {
      return "#F48FB1";
    } else if (index < 9) {
      return "#80CBC4";
    } else if (index < 11) {
      return "#FFAB91";
    } else if (index < 22) {
      return "#81D4FA";
    } else if (index < 27) {
      return "#B39DDB";
    } else if (index < 30) {
      return "#A5D6A7";
    }

    return "#f0f3f4";
  };

  // ** Functions
  const onClose = () => {
    setDialogState(false);
  };

  return (
    <Dialog
      fullWidth
      open={dialogState}
      maxWidth="md"
      scroll="body"
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          background: "rgba(255, 255, 255, 0.13) !important",
          backdropFilter: "blur(16px)",
        },
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 5 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.09)",
            p: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.09)",
              borderBottom: "3px solid rgba(255, 255, 255, 0.75)",
              p: 1,
              borderRadius: 1,
            }}
          >
            <TimerTwoTone
              sx={{
                color: "#f0f3f4",
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "1.3rem",
              color: "#f0f3f4",
              fontWeight: 600,
            }}
          >
            Çalışma Süresi Analizi
          </Typography>
          <IconButton
            sx={{
              color: "#f0f3f4",
            }}
            onClick={onClose}
            size="small"
            aria-label="settings"
          >
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3.5, px: { xs: 1, md: 10 } }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexWrap: "wrap",
              background: "rgb(33, 36, 45)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
              gap: 2.5,
            }}
          >
            <InfoTwoTone
              sx={{
                fontSize: "1.75rem",
                color: "#d0d3d4",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontStyle: "italic",
                  color: "#f0f3f4",
                }}
              >
                Etiket makinesinin çalışma durumuna göre hesaplanan aktif
                çalışma süresi.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(240deg 5% 12%) 0%, hsl(240deg 11% 12%) 38%, hsl(240deg 18% 7%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: expandedPaused
                  ? "1px solid rgba(255,255,255, 0.13)"
                  : "none",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f0f3f4",
                      fontWeight: 600,
                    }}
                  >
                    Çalışma Süresi
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 2,
                  px: 0.8,
                  py: 0.4,
                  backgroundColor: "rgba(255, 255, 255, 0.13)",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.21)",
                  },
                  border: "1px solid rgb(255, 255, 255, 0.24)",
                }}
              >
                <Typography
                  sx={{
                    color: "#f0f3f4",
                    fontWeight: 600,
                  }}
                >
                {((lineInfoData.WorkingTime + lineInfoData.DownTime) / 60).toFixed(0)} dk
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label="LineWorkingTime" top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Aktif Çalışma Süresi
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {(lineInfoData.WorkingTime / 60).toFixed(0)} dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label="LineDownTime" top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Duruş Süresi
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {(lineInfoData.DownTime / 60).toFixed(0)} dk
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography
              sx={{
                color: "#f0f3f4",
                fontWeight: 700,
                fontSize: "1.15rem",
              }}
            >
              Hesaplamalar
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(211deg 29% 25%) 0%, hsl(222deg 33% 18%) 59%, hsl(234deg 36% 11%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <DebugModeInfo label={"LineWorkingTime"} top={0} left={0} />
                  <Typography
                    sx={{
                      color: "#AF7AC5",
                      fontSize: "1.25rem",
                      fontWeight: 700,
                    }}
                  >
                    Çalışma Süresi
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#AF7AC5",
                      fontWeight: 600,
                    }}
                  >
                    =
                  </Typography>
                  <Typography
                    sx={{
                      color: "#efe4f3",
                      fontStyle: "italic",
                    }}
                  >
                    Planlanan Üretim Süresi - Durma Süresi
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  color: "#AF7AC5",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                }}
              >
                {lineInfoData.WorkingTime && (lineInfoData.WorkingTime / 60).toFixed(0)} dk
                </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo
                  label={"LinePlannedProductionTime"}
                  top={0}
                  left={0}
                />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Planlanan Üretim Süresi
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {lineInfoData.PlannedProductionTime} dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineDownTime"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Durma Süresi (Planlanmış Duruşlar + Planlanmamış Duruşlar)
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {lineInfoData.DownTime && (lineInfoData.DownTime / 60).toFixed(0)} dk
                </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 48px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 6,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineUnplannedStops"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Planlanmamış Duruşlar (ör. Arızalar)
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {lineInfoData.UnplannedStops && lineInfoData.UnplannedStops.toFixed(0)} dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 48px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
                ml: 6,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LinePlannedStops"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Planlanmış Duruşlar (ör. Ayarlamalar)
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {lineInfoData.PlannedStops} dk
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              flexWrap: "wrap",
              backgroundImage:
                "linear-gradient(140deg, hsl(211deg 29% 25%) 0%, hsl(222deg 33% 18%) 59%, hsl(234deg 36% 11%) 100%)",
              border: "1px solid rgba(255,255,255, 0.24)",
              borderRadius: 1,
              py: 1,
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row-reverse",
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#16A085",
                      fontSize: "1.25rem",
                      fontWeight: 700,
                    }}
                  >
                    Çalışma Süresi
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#16A085",
                      fontWeight: 600,
                    }}
                  >
                    =
                  </Typography>
                  <Typography
                    sx={{
                      color: "#d0ece7",
                      fontStyle: "italic",
                    }}
                  >
                    Aktif Çalışma Süresi + Duruş Süresi
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  color: "rgb(22, 160, 133)",
                  fontWeight: 700,
                  fontSize: "1.25rem",
                }}
              >
                {((lineInfoData.WorkingTime + lineInfoData.DownTime) / 60).toFixed(0)} dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(255,255,255, 0.13)",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineWorkingTime"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Aktif Çalışma Süresi
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {(lineInfoData.WorkingTime / 60).toFixed(0)} dk
              </Typography>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 24px)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 0.5,
                ml: 3,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row-reverse",
                  gap: 1.5,
                }}
              >
                <DebugModeInfo label={"LineDownTime"} top={0} left={0} />
                <Typography
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  Duruş Süresi
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#f0f3f4",
                }}
              >
                {(lineInfoData.DownTime / 60).toFixed(0)} dk
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LineRunInfoDialog;
