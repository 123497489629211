import React, { useEffect, useState } from "react";
import MachineLight from "./MachineLight";
import type {} from "@mui/x-data-grid/themeAugmentation";

import { Box, Container, Typography } from "@mui/material";
import {
  AccessTimeRounded,
  AutoModeRounded,
  EastRounded,
  InfoRounded,
  InfoTwoTone,
  Inventory2Rounded,
  StormRounded,
  SwitchAccessShortcutAddRounded,
  ThreeSixtyRounded,
} from "@mui/icons-material";
import SelectedMachineInfoDialog from "./SelectedMachineInfoDialog";
import ProductivityInfoDialog from "./ProductivityInfoDialog";
import DebugModeInfo from "./DebugModeInfo";
import { Machines, Message } from "../message";
import Moment from "react-moment";
import LineDownInfoDialog from "./LineDownInfoDialog";
import LineBreakInfoDialog from "./LineBreakInfoDialog";
import LineRunInfoDialog from "./LineRunInfoDialog";
import { convertStartedOrFinished } from "../pages/PageShiftDetailsPage";

type MainAnalyticsProps = {
  message: Message;
};

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export const convertMsToTime = (seconds: number) => {
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;

  return {
    digitalColock: `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
      seconds
    )}`,
    hours,
    minutes,
    seconds,
  };
};

export const convertMillisecondToTime = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`;
};

const MainAnalytics = (props: MainAnalyticsProps) => {
  const { message } = props;

  // ** States
  const [lineStateRatio, setLineStateRatio] = useState<number>(0);

  const [lineDownInfoDialogState, setLineDownInfoDialogState] =
    useState<boolean>(false);

  const [lineBreakInfoDialogState, setLineBreakInfoDialogState] =
    useState<boolean>(false);

  const [lineRunInfoDialogState, setLineRunInfoDialogState] =
    useState<boolean>(false);

  const [productivityInfoDialogState, setProductivityInfoDialogState] =
    useState<boolean>(false);

  const [selectedMachine, setSelectedMachine] = useState<Machines[0] | null>(
    null
  );

  const getLineState = (line: any) => {
    if (line.StartScheduledStops) {
      return "236, 240, 241";
    }

    if (line.Green) {
      return "2, 224, 164";
    }

    if (line.Red) {
      return "255, 76, 81";
    }

    if (line.Yellow) {
      return "243, 156, 18";
    }

    return "236, 240, 241";
  };

  const getLineStateText = (line: any) => {
    if (line.StartScheduledStops) {
      return "Hat Bakım ve Temizlik Modunda";
    }

    if (line.Green) {
      return "Hat Çalışıyor";
    }

    if (line.Red) {
      return "Hat Durduruldu";
    }

    if (line.Yellow) {
      return "Hat Bekliyor";
    }

    return "Vardiya Başlamadı";
  };

  useEffect(() => {
    setLineStateRatio(
      Number(
        (
          ((message.Line.PresentTime ? message.Line.PresentTime : 0) / 43200) *
          100
        ).toFixed(2)
      )
    );
  }, [message.Line.PresentTime]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container
        disableGutters
        maxWidth={"xl"}
        sx={{ pt: { xs: 28, sm: 21, md: 12 }, pb: 3 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 1,
            borderRadius: 2,
            backdropFilter: "blur(16px)",
            flexWrap: "wrap",
            gap: 0.8,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              pl: 0.8,
              pr: 1.5,
              py: 0.8,
              borderRadius: 2,
              maxHeight: 42,
              borderLeft: `3px solid rgb(${getLineState(message.Line)})`,
              background: `rgba(${getLineState(message.Line)}, 0.21)`,
            }}
          >
            <InfoRounded
              fontSize="medium"
              sx={{
                color: `rgb(${getLineState(message.Line)})`,
              }}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '1.15rem',
                color: `rgb(${getLineState(message.Line)})`,
              }}
            >
              {getLineStateText(message.Line)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            minWidth: 128,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Moment
            style={{
              color: "#C9CFF2",
            }}
            className="desc"
            format="DD-MM-yyyy HH:mm:ss"
          >
            {Date.now()}
          </Moment>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              flexWrap: "wrap",
            }}
          >
            <Typography
              align="center"
              sx={{
                fontWeight: 600,
                fontSize: "1.3rem !important",
                color: "#FBFBFC",
                animation: "lights 5s 750ms linear infinite",
                "@keyframes lights": {
                  "0%": {
                    color: "rgba",
                    textShadow:
                      "0 0 1em hsla(320, 100%, 50%, 0.2), 0 0 0.13em hsla(320, 100%, 60%, 0.3), -1em -0.13em 0.5em hsla(40, 100%, 60%, 0), 1em 0.13em 0.5em hsla(200, 100%, 60%, 0)",
                  },
                  "30%": {
                    color: "hsl(230, 80%, 90%)",
                    textShadow:
                      "0 0 1em hsla(320, 100%, 50%, 0.5), 0 0 0.13em hsla(320, 100%, 60%, 0.5), -0.5em -0.13em 0.25em hsla(40, 100%, 60%, 0.2), 0.5em 0.13em 0.25em hsla(200, 100%, 60%, 0.4)",
                  },

                  "40%": {
                    color: "hsl(230, 100%, 95%)",
                    textShadow:
                      "0 0 1em hsla(320, 100%, 50%, 0.5), 0 0 0.13em hsla(320, 100%, 90%, 0.5), -0.25em -0.13em 0.13em hsla(40, 100%, 60%, 0.2), 0.25em 0.13em 0.13em hsla(200, 100%, 60%, 0.4)",
                  },

                  "70%": {
                    color: "hsl(230, 80%, 90%)",
                    textShadow:
                      "0 0 1em hsla(320, 100%, 50%, 0.5), 0 0 0.13em hsla(320, 100%, 60%, 0.5), 0.5em -0.13em 0.25em hsla(40, 100%, 60%, 0.2), -0.5em 0.13em 0.25em hsla(200, 100%, 60%, 0.4)",
                  },
                  "100%": {
                    color: "hsl(230, 40%, 80%)",
                    textShadow:
                      "0 0 1em hsla(320, 100%, 50%, 0.2), 0 0 0.13em hsla(320, 100%, 60%, 0.3), 1em -0.13em 0.5em hsla(40, 100%, 60%, 0), -1em 0.13em 0.5em hsla(200, 100%, 60%, 0)",
                  },
                },
              }}
            >
              CANLI
            </Typography>
            <Typography
              align="center"
              sx={{
                fontWeight: 600,
                fontSize: "1.3rem !important",
                color: "#C9CFF2",
              }}
            >
              ŞURUP ÜRETİM HATTI İZLEME PANELİ
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <div
            className="card"
            style={{
              paddingBottom: 4,
              backgroundColor: `rgba(${getLineState(message.Line)}, 0.21)`,
              color: `rgb(${getLineState(message.Line)})`,
              border: `1px solid rgb(${getLineState(message.Line)})`,
            }}
          >
            <Box
              sx={{
                width: "100%",
                py: 1,
                px: 1.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  minWidth: 68,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "0.75rem !important",
                    color: `rgb(${getLineState(message.Line)})`,
                  }}
                >
                  Vardiya Başlangıç
                </Typography>
                <Typography
                  align="left"
                  sx={{
                    fontWeight: 600,
                    fontSize: "1rem !important",
                    color: `rgb(${getLineState(message.Line)})`,
                  }}
                >
                  {convertStartedOrFinished(message.started_at || 0)}
                </Typography>
              </Box>
              <Box>
                <Typography
                  align="center"
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.2rem !important",
                    color: `rgb(${getLineState(message.Line)})`,
                  }}
                >
                  Vardiya Bilgileri
                </Typography>
              </Box>
              <Box
                sx={{
                  minWidth: 68,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "0.75rem !important",
                    color: `rgb(${getLineState(message.Line)})`,
                  }}
                >
                  Anlık Vardiya Süresi
                </Typography>
                <Typography
                  align="left"
                  sx={{
                    fontWeight: 600,
                    fontSize: "1rem !important",
                    color: `rgb(${getLineState(message.Line)})`,
                  }}
                >
                  {convertMsToTime(message.Line.PresentTime).digitalColock}
                </Typography>
              </Box>
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              flexDirection="column"
              flexWrap="wrap"
              alignItems="center"
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div
                  className="card"
                  style={{
                    width: 210,
                    paddingTop: 8,
                    paddingRight: 12,
                    paddingBottom: 8,
                    paddingLeft: 12,
                    margin: 5,
                    marginLeft: 9,
                    minWidth: 180,
                    cursor: "pointer",
                    borderTop: "1px solid rgb(255, 76, 81)",
                  }}
                  onClick={() => setLineDownInfoDialogState(true)}
                >
                  <DebugModeInfo label="LineDownTime" top={-10} />
                  <InfoTwoTone
                    sx={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      fontSize: "1rem",
                      color: "#d0d3d4",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      minHeight: 72,
                    }}
                  >
                    <Box
                      sx={{
                        width: 42,
                        p: 0,
                        height: 42,
                        display: "flex",
                        borderRadius: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255, 76, 81, 0.21)",
                        color: "rgba(255, 76, 81)",
                      }}
                    >
                      <AccessTimeRounded
                        sx={{
                          fontSize: 48,
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                        }}
                      >
                        Duruş Süresi
                      </Typography>
                      <Typography
                        sx={{
                          mt: 1,
                          fontWeight: 700,
                          fontSize: "1.2rem",
                          lineHeight: "1.2rem",
                          color: "#FBFCFC !important",
                        }}
                      >
                        {convertMsToTime(message.Line.DownTime).digitalColock}
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div
                  className="card"
                  style={{
                    width: 210,
                    paddingTop: 8,
                    paddingRight: 12,
                    paddingBottom: 8,
                    paddingLeft: 12,
                    margin: 5,
                    minWidth: 180,
                    cursor: "pointer",
                    borderTop: "1px solid rgb(243, 156, 18)",
                  }}
                  onClick={() => setLineBreakInfoDialogState(true)}
                >
                  <DebugModeInfo label="LineBreaksTime" top={-10} />
                  <InfoTwoTone
                    sx={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      fontSize: "1rem",
                      color: "#d0d3d4",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      minHeight: 72,
                    }}
                  >
                    <Box
                      sx={{
                        width: 42,
                        p: 0,
                        height: 42,
                        mr: 1.5,
                        display: "flex",
                        borderRadius: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(243, 156, 18, 0.21)",
                        color: "rgb(243, 156, 18)",
                      }}
                    >
                      <AccessTimeRounded
                        sx={{
                          fontSize: 48,
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                        }}
                      >
                        Mola Süresi
                      </Typography>
                      <Typography
                        sx={{
                          mt: 1,
                          fontWeight: 700,
                          fontSize: "1.2rem",
                          lineHeight: "1.2rem",
                          color: "#FBFCFC",
                        }}
                      >
                        {convertMsToTime(message.Line.BreaksTime).digitalColock}
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div
                  className="card"
                  style={{
                    width: 210,
                    paddingTop: 8,
                    paddingRight: 12,
                    paddingBottom: 8,
                    paddingLeft: 12,
                    margin: 5,
                    minWidth: 180,
                    cursor: "pointer",
                    borderTop: "1px solid rgb(2, 224, 164)",
                  }}
                  onClick={() => setLineRunInfoDialogState(true)}
                >
                  <DebugModeInfo label="LineWorkingTime" top={-10} />
                  <InfoTwoTone
                    sx={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      fontSize: "1rem",
                      color: "#d0d3d4",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      minWidth: 172,
                      minHeight: 72,
                    }}
                  >
                    <Box
                      sx={{
                        width: 42,
                        p: 0,
                        height: 42,
                        display: "flex",
                        borderRadius: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(2, 224, 164, 0.21)",
                        color: "rgb(2, 224, 164)",
                      }}
                    >
                      <AccessTimeRounded
                        sx={{
                          fontSize: 48,
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                        }}
                      >
                        Çalışma Süresi
                      </Typography>
                      <Typography
                        sx={{
                          mt: 1,
                          fontWeight: 700,
                          fontSize: "1.2rem",
                          lineHeight: "1.2rem",
                          color: "#FBFCFC",
                        }}
                      >
                        {
                          convertMsToTime(message.Line.WorkingTime)
                            .digitalColock
                        }
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div
                  className="card"
                  style={{
                    width: 210,
                    paddingTop: 8,
                    paddingRight: 12,
                    paddingBottom: 8,
                    paddingLeft: 12,
                    margin: 5,
                    marginRight: 9,
                    borderTop: "1px solid rgb(236, 240, 241)",
                  }}
                >
                  <DebugModeInfo label="LineIdealRunRatePPM" top={-10} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      minHeight: 72,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: 42,
                        p: 0,
                        height: 42,
                        mr: 1.5,
                        display: "flex",
                        borderRadius: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(236, 240, 241, 0.21)",
                        color: "rgb(236, 240, 241)",
                        borderBottom: "2px solid rgb(236, 240, 241)",
                      }}
                    >
                      <ThreeSixtyRounded
                        sx={{
                          fontSize: 32,
                          margin: 5,
                          filter:
                            "drop-shadow(1px 3px 5px rgba(236, 240, 241, 0.81))",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                        }}
                      >
                        Hat Hızı
                      </Typography>
                      <Typography
                        sx={{
                          mt: 1,
                          fontWeight: 700,
                          fontSize: "1.2rem",
                          lineHeight: "1.2rem",
                          color: "#FBFCFC",
                        }}
                      >
                        {message.Line.IdealRunRatePPM}
                      </Typography>
                      <Typography
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          fontWeight: 700,
                          fontSize: "0.7rem",
                          lineHeight: "0.7rem",
                          color: "#CACFD2",
                        }}
                      >
                        adet / dk
                      </Typography>
                    </Box>
                  </Box>
                </div>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div
                  className="card"
                  style={{
                    width: 210,
                    paddingTop: 8,
                    paddingRight: 12,
                    paddingBottom: 8,
                    paddingLeft: 12,
                    margin: 5,
                    borderBottom: "1px solid rgb(2, 224, 164)",
                  }}
                >
                  <DebugModeInfo
                    label="LineVerifiedBottleTotalPieces"
                    top={-10}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      minHeight: 72,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: 42,
                        p: 0,
                        height: 42,
                        mr: 1.5,
                        display: "flex",
                        borderRadius: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(2, 224, 164, 0.21)",
                        color: "rgb(2, 224, 164)",
                        borderBottom: "2px solid rgb(2, 224, 164)",
                      }}
                    >
                      <svg
                        style={{
                          margin: 5,
                          filter:
                            "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                        }}
                        width="64px"
                        height="64px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke="#2, 224, 164"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                            d="M4 15.8294V15.75V8C4 7.69114 4.16659 7.40629 4.43579 7.25487L4.45131 7.24614L11.6182 3.21475L11.6727 3.18411C11.8759 3.06979 12.1241 3.06979 12.3273 3.18411L19.6105 7.28092C19.8511 7.41625 20 7.67083 20 7.94687V8V15.75V15.8294C20 16.1119 19.8506 16.3733 19.6073 16.5167L12.379 20.7766C12.1451 20.9144 11.8549 20.9144 11.621 20.7766L4.39267 16.5167C4.14935 16.3733 4 16.1119 4 15.8294Z"
                            stroke="#02e0a4"
                            strokeWidth="2"
                          ></path>
                          <path
                            d="M12 21V12"
                            stroke="#02e0a4"
                            strokeWidth="2"
                          ></path>
                          <path
                            d="M12 12L4 7.5"
                            stroke="#02e0a4"
                            strokeWidth="2"
                          ></path>
                          <path
                            d="M20 7.5L12 12"
                            stroke="#02e0a4"
                            strokeWidth="2"
                          ></path>
                        </g>
                      </svg>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                        }}
                      >
                        Kutulanan Ürün
                      </Typography>
                      <Typography
                        sx={{
                          mt: 1,
                          fontWeight: 700,
                          fontSize: "1.2rem",
                          lineHeight: "1.2rem",
                          color: "#FBFCFC",
                        }}
                      >
                        {message.Line.VerifiedBottleTotalPieces}
                      </Typography>
                      <Typography
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          fontWeight: 700,
                          fontSize: "0.7rem",
                          lineHeight: "0.7rem",
                          color: "#CACFD2",
                        }}
                      >
                        adet
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div
                  className="card"
                  style={{
                    width: 210,
                    paddingTop: 8,
                    paddingRight: 12,
                    paddingBottom: 8,
                    paddingLeft: 12,
                    margin: 5,
                    borderBottom: "1px solid rgb(243, 156, 18)",
                  }}
                >
                  <DebugModeInfo label="LineVerifiedBoxTotalPieces" top={-10} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      minHeight: 72,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: 42,
                        p: 0,
                        height: 42,
                        mr: 1.5,
                        display: "flex",
                        borderRadius: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(243, 156, 18, 0.21)",
                        color: "rgb(243, 156, 18)",
                        borderBottom: "2px solid rgb(243, 156, 18)",
                      }}
                    >
                      <AutoModeRounded
                        sx={{
                          filter:
                            "drop-shadow(1px 3px 5px rgba(243, 156, 18, 0.81))",
                        }}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                        }}
                      >
                        Kutulama Tekrar
                      </Typography>
                      <Typography
                        sx={{
                          mt: 1,
                          fontWeight: 700,
                          fontSize: "1.2rem",
                          lineHeight: "1.2rem",
                          color: "#FBFCFC",
                        }}
                      >
                        {message.Line.RecycleBottles}
                      </Typography>
                      <Typography
                        sx={{
                          position: "absolute",
                          bottom: 0,
                          fontWeight: 700,
                          fontSize: "0.7rem",
                          lineHeight: "0.7rem",
                          color: "#CACFD2",
                        }}
                      >
                        adet
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div
                  className="card"
                  style={{
                    cursor: "pointer",
                    width: 210,
                    paddingTop: 8,
                    paddingRight: 12,
                    paddingBottom: 8,
                    paddingLeft: 12,
                    margin: 5,
                    borderBottom: "1px solid rgb(255, 139, 0)",
                  }}
                  onClick={() => setProductivityInfoDialogState(true)}
                >
                  <DebugModeInfo label="LineWorkingTime" top={-10} />
                  <InfoTwoTone
                    sx={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      fontSize: "1rem",
                      color: "#d0d3d4",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      minHeight: 72,
                    }}
                  >
                    <Box
                      sx={{
                        width: 42,
                        p: 0,
                        height: 42,
                        display: "flex",
                        borderRadius: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255, 139, 0, 0.21)",
                        color: "rgb(255, 139, 0)",
                        borderBottom: "2px solid rgb(255, 139, 0)",
                      }}
                    >
                      <SwitchAccessShortcutAddRounded
                        sx={{
                          fontSize: 32,
                          margin: 5,
                          filter:
                            "drop-shadow(1px 3px 5px rgba(255, 139, 0, 0.81))",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                        }}
                      >
                        Kullanılabilirlik
                      </Typography>
                      <Typography
                        sx={{
                          mt: 1,
                          fontWeight: 700,
                          fontSize: "1.2rem",
                          lineHeight: "1.2rem",
                          color: "#FBFCFC",
                        }}
                      >
                        {message.Line.Availability
                          ? message.Line.Availability.toFixed(1)
                          : 0}
                        %
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div
                  className="card"
                  style={{
                    cursor: "pointer",
                    width: 210,
                    paddingTop: 8,
                    paddingRight: 12,
                    paddingBottom: 8,
                    paddingLeft: 12,
                    margin: 5,
                    borderBottom: "1px solid rgb(253, 202, 64)",
                  }}
                  onClick={() => setProductivityInfoDialogState(true)}
                >
                  <DebugModeInfo label="LineWorkingTime" top={-10} />
                  <InfoTwoTone
                    sx={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      fontSize: "1rem",
                      color: "#d0d3d4",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      minHeight: 72,
                    }}
                  >
                    <Box
                      sx={{
                        width: 42,
                        p: 0,
                        height: 42,
                        display: "flex",
                        borderRadius: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(253, 202, 64, 0.21)",
                        color: "rgb(253, 202, 64)",
                        borderBottom: "2px solid rgb(253, 202, 64)",
                      }}
                    >
                      <StormRounded
                        sx={{
                          fontSize: 32,
                          margin: 5,
                          filter:
                            "drop-shadow(1px 3px 5px rgba(253, 202, 64, 0.81))",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                        }}
                      >
                        Performans
                      </Typography>
                      <Typography
                        sx={{
                          mt: 1,
                          fontWeight: 700,
                          fontSize: "1.2rem",
                          lineHeight: "1.2rem",
                          color: "#FBFCFC",
                        }}
                      >
                        {message.Line.Performance
                          ? message.Line.Performance.toFixed(1)
                          : 0}{" "}
                        %
                      </Typography>
                    </Box>
                  </Box>
                </div>
                {/* 
                                  <Productivity
                  productivityValue={productivityDatas.OEE}
                  onDialogOpen={() => setProductivityInfoDialogState(true)}
                />
                  */}
              </Box>
            </Box>
          </div>
        </Box>

        <SelectedMachineInfoDialog
          selectedMachine={selectedMachine}
          setSelectedMachine={setSelectedMachine}
        />
        <LineDownInfoDialog
          lineInfoData={message.Line}
          machines={message.Machines}
          dialogState={lineDownInfoDialogState}
          setDialogState={setLineDownInfoDialogState}
        />
        <LineBreakInfoDialog
          lineInfoData={message.Line}
          dialogState={lineBreakInfoDialogState}
          setDialogState={setLineBreakInfoDialogState}
        />
        <LineRunInfoDialog
          lineInfoData={message.Line}
          dialogState={lineRunInfoDialogState}
          setDialogState={setLineRunInfoDialogState}
        />
        <ProductivityInfoDialog
          productivityInfoData={message.Line}
          machines={message.Machines}
          dialogState={productivityInfoDialogState}
          setDialogState={setProductivityInfoDialogState}
        />
      </Container>
      <Container
        maxWidth={"xl"}
        sx={{
          minHeight: 300,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: { xs: "center", lg: "space-around" },
            gap: 1.5,
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: 52 },
              height: { xs: 52, sm: 256 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: { xs: 3, sm: 0 },
              flexDirection: { xs: "column", sm: "row" },
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: 42,
                height: 42,
                px: 0,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(4, 158, 230, 0.36)",
                color: "rgba(4, 158, 230)",
                borderBottom: "2px solid rgb(4, 158, 230)",
              }}
            >
              <svg
                style={{
                  margin: 7,
                  filter: "drop-shadow(1px 3px 5px rgba(4, 158, 230, 0.81))",
                }}
                fill="#1F3140"
                height="64px"
                width="64px"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-26.68 -26.68 320.14 320.14"
                enableBackground="new 0 0 266.777 266.777"
                transform="rotate(0)"
                stroke="#1F3140"
                strokeWidth="0.00266777"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#049ee6"
                  strokeWidth="53.355399999999996"
                >
                  <path d="m179.638,90.081c-7.693-6.157-11.276-15.514-11.276-29.446v-17.635h11.527v-43h-93v43h11.527v17.635c0,14.874-3.073,23.681-10.276,29.446-19.44,15.559-27.369,31.983-27.369,56.692v100.088c0,10.981 8.934,19.916 19.915,19.916h105.405c10.981,0 19.916-8.935 19.916-19.916v-100.088c0-22.616-9.611-43.28-26.369-56.692z"></path>{" "}
                </g>
                <g id="SVGRepo_iconCarrier">
                  <path d="m179.638,90.081c-7.693-6.157-11.276-15.514-11.276-29.446v-17.635h11.527v-43h-93v43h11.527v17.635c0,14.874-3.073,23.681-10.276,29.446-19.44,15.559-27.369,31.983-27.369,56.692v100.088c0,10.981 8.934,19.916 19.915,19.916h105.405c10.981,0 19.916-8.935 19.916-19.916v-100.088c0-22.616-9.611-43.28-26.369-56.692z"></path>{" "}
                </g>
              </svg>
            </Box>
            <EastRounded
              sx={{
                transform: { xs: "rotate(90deg)", sm: "rotate(0deg)" },
                fontSize: "1.7rem",
                color: "#f0f3f4",
              }}
            />
          </Box>
          {message.Machines.map((machine, index) => (
            <>
              <MachineLight
                title={machine.name}
                machine={machine}
                tableInfo={
                  machine.name === "Dolum"
                    ? message.Line.DolumTurnTableFull
                    : false
                }
                selectedMachine={selectedMachine}
                setSelectedMachine={setSelectedMachine}
              />
              {index + 1 !== message.Machines.length && (
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                    justifyContent: "center",
                    mt: 15,
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    className="arrows-body-2"
                    style={{
                      visibility: message.Line.Green ? "visible" : "hidden",
                    }}
                  >
                    <div className={`arrow-${index + 1}`}></div>
                    <div className={`arrow-${index + 1}`}></div>
                    <div className={`arrow-${index + 1}`}></div>
                  </div>
                </Box>
              )}
            </>
          ))}
          <Box
            sx={{
              width: { xs: "100%", sm: 52 },
              height: { xs: 52, sm: 256 },
              mt: { xs: 3, sm: 0 },
              display: "flex",
              justifyContent: "center",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              gap: 1,
            }}
          >
            <EastRounded
              sx={{
                transform: { xs: "rotate(90deg)", sm: "rotate(0deg)" },
                fontSize: "1.7rem",
                color: "#f0f3f4",
              }}
            />
            <Box
              sx={{
                width: 42,
                p: 0,
                height: 42,
                display: "flex",
                borderRadius: "50%",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(2, 224, 164, 0.36)",
                color: "rgb(2, 224, 164)",
                borderBottom: "2px solid rgb(2, 224, 164)",
              }}
            >
              <Inventory2Rounded
                sx={{
                  margin: 3,
                  filter: "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default MainAnalytics;
