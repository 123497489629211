// ** Icons Imports
import {
  BuildCircleRounded,
  CloseRounded,
  DoneRounded,
  FlagTwoTone,
  Looks3Rounded,
  Looks4Rounded,
  Looks5Rounded,
  Looks6Rounded,
  LooksOneRounded,
  LooksTwoRounded,
} from "@mui/icons-material";
import MuiToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";

// ** MUI Imports
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormControlLabelProps,
  Grow,
  IconButton,
  Radio,
  RadioGroup,
  RadioProps,
  styled,
  TextField,
  ToggleButtonGroup,
  Typography,
  useRadioGroup,
} from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { ErrorInfoChange } from "../pages/InputsPage";
import axios from "axios";
import Moment from "react-moment";
import TimePicker from "./slider-time-picker";
import { Error, MachineType } from "../message";
import { convertMsToTime } from "./MainAnalytics";
import secureLocalStorage from "react-secure-storage";

// ** Types
type ErrorChangeDialogProps = {
  accessToken: string;
  errorInfo: ErrorInfoChange;
  setErrorInfo: (value: React.SetStateAction<ErrorInfoChange>) => void;
  dialogState: boolean;
  setDialogState: (value: React.SetStateAction<boolean>) => void;
};

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme }) => ({
  variants: [
    {
      props: { checked: true },
      style: {
        ".MuiFormControlLabel-label": {
          color: theme.palette.primary.main,
        },
      },
    },
  ],
}));

function MyFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    background: "rgba(0, 0, 0, 0.24)",
    color: "#f0f3f4",
    fontSize: "1.5rem",
    "& fieldset": {
      borderWidth: "1px",
      borderColor: "rgba(255, 255, 255, 0.24)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(255, 255, 255, 0.48)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(255, 255, 255, 0.52)",
    },
  },
});

// Styled ToggleButton component
const ToggleButton = styled(MuiToggleButton)<ToggleButtonProps>(
  ({ theme }) => ({
    border: "none !important",
    textTransform: "capitalize",
    fontWeight: 700,
    color: "#f0f3f4",
    "&.Mui-selected, &.Mui-selected:hover": {
      backdropFilter: "blur(16px)",
      color: "#f0f3f4",
      backgroundColor: "rgba(255, 255, 255, 0.13)",
    },
    "&:not(:first-of-type)": {
      borderRadius: `13px !important`,
    },
    "&:first-of-type": {
      borderRadius: `13px !important`,
    },
  })
);

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 32,
  height: 32,

  ".Mui-focusVisible &": {
    outline: "2px auto rgba(255,255,255,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "rgba(255, 255, 255, 0.07)",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(57,75,89,.5)",
  },
  boxShadow: "0 0 0 1px rgb(16 22 26 / 40%)",
  backgroundColor: "rgba(255, 255, 255, 0.04)",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "rgba(255, 255, 255, 0.11)",
  "&::before": {
    display: "block",
    width: 32,
    height: 32,
    backgroundImage: "radial-gradient(#f0f3f4,#f0f3f4 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "rgba(255, 255, 255, 0.22)",
  },
});

// Inspired by blueprintjs
function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="success"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
      sx={{
        "&, & + .MuiFormControlLabel-label": {
          fontSize: "1.5rem",
          color: "#999",
        },
        "&.Mui-checked": {
          "&, & + .MuiFormControlLabel-label": {
            color: "#f0f3f4",
          },
        },
      }}
    />
  );
}

const API_URL = process.env.REACT_APP_API_HOST || "";

const ErrorChangeDialog = (props: ErrorChangeDialogProps) => {
  // ** Props
  const { errorInfo, setErrorInfo, dialogState, setDialogState, accessToken } =
    props;

  // ** States
  const [value, setValue] = useState<MachineType | null>("GENERAL");
  const [allErrors, setAllErrors] = useState<Error[]>([]);
  const [selectedErrors, setSelectedErrors] = useState<Error[]>([]);
  const [selectedErrorTitle, setSelectedErrorTitle] = useState<string | null>(
    null
  );
  const [errorDescription, setErrorDescription] = useState<string>("");

  const [durationValue, setDurationValue] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // onChange
  const handleTimeChange = (time: {
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    const splitted = String(time).split(":");
    const valueOfDuration =  (Number(splitted[0]) * 3600 +
    Number(splitted[1]) * 60 +
    Number(splitted[2]))

    setErrorInfo({
      ...errorInfo,
      startedAt:
        errorInfo.solvedAt -
        (Number(splitted[0]) * 3600 +
          Number(splitted[1]) * 60 +
          Number(splitted[2])) *
          1000,
          duration: valueOfDuration,
    });
    setDurationValue(time);
  };

  const handleOperatorChange = (
    event: MouseEvent<HTMLElement>,
    pValue: MachineType | null
  ) => {
    setValue(pValue);
    setErrorInfo({ ...errorInfo, machine: pValue });
  };

  // ** Functions
  const onClose = () => {
    const filtered = allErrors.filter(
      (item) => item.machine_type === "GENERAL"
    );
    setSelectedErrors(filtered);
    setSelectedErrorTitle(filtered[0]?.title);
    setErrorDescription("");
    setErrorInfo({
      title: "",
      machine: "",
      duration: 0,
      description: undefined,
      isStarted: false,
      startedAt: Date.now(),
      solvedAt: Date.now(),
    });
    setDialogState(false);
    secureLocalStorage.removeItem('errorInfo');
  };

  const handleErrorTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedErrorTitle((event.target as HTMLInputElement).value);
  };

  // **
  const onSaveErrorInfo = () => {
    if (selectedErrorTitle || value) {
      axios
        .post(
          API_URL + "/setError",
          {
            title: selectedErrorTitle,
            description: errorDescription,
            machine_type: value,
            duration: errorInfo.duration,
            started_at: errorInfo.startedAt,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-token": accessToken,
            },
          }
        )
        .then(() => {
          const filtered = allErrors.filter(
            (item) => item.machine_type === "GENERAL"
          );
          setSelectedErrors(filtered);
          setSelectedErrorTitle(filtered[0]?.title);
          setErrorDescription("");
          setErrorInfo({
            title: "",
            machine: "",
            duration: 0,
            description: undefined,
            isStarted: false,
            startedAt: Date.now(),
            solvedAt: Date.now(),
          });
          setDialogState(false);
          secureLocalStorage.removeItem('errorInfo');
        });
    }
  };

  useEffect(() => {
    if (dialogState) {
      const time = convertMsToTime(errorInfo.duration);
      setDurationValue({
        hours: time.hours,
        minutes: time.minutes,
        seconds: time.seconds,
      });
      setErrorInfo({
        ...errorInfo,
        solvedAt: Date.now(),
        startedAt: Date.now() - errorInfo.duration * 1000,
      });
      axios
        .get(API_URL + "/getErrors", {
          headers: {
            "Content-Type": "application/json",
            "x-token": accessToken,
          },
        })
        .then((result) => {
          setAllErrors(result.data);
          setValue("GENERAL");
          const filtered: Error[] = result.data.filter(
            (item: Error) => item.machine_type === "GENERAL"
          );
          setSelectedErrors(filtered);
          setSelectedErrorTitle(filtered[0]?.title);
        });
    }
  }, [dialogState]);

  useEffect(() => {
    const filtered = allErrors.filter((item) => item.machine_type === value);
    setSelectedErrors(filtered);
    setSelectedErrorTitle(filtered[0]?.title);
  }, [value, allErrors]);

  return (
    <Dialog
      fullWidth
      open={dialogState}
      maxWidth="xl"
      scroll="body"
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          backgroundColor: "rgba(255, 255, 255, 0.13) !important",
        },
      }}
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.09)",
            p: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.09)",
              borderBottom: "3px solid rgba(255, 255, 255, 0.72)",
              p: 1,
              borderRadius: 1,
            }}
          >
            <FlagTwoTone
              fontSize="large"
              sx={{
                color: "#f0f3f4",
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "calc(1rem + 1vh)",
              color: "#f0f3f4",
              fontWeight: 500,
            }}
          >
            Hata Çözüldü
          </Typography>
          <IconButton
            sx={{
              color: "#f0f3f4",
            }}
            onClick={onClose}
            size="large"
            aria-label="settings"
          >
            <CloseRounded fontSize="large" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ m: 0, p: 3.5, background: "rgba(0,0,0, 0.42)" }}>
        <Box>
          <Box
            sx={{
              width: "100%",
              minHeight: 100,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "calc(0.85rem + 1vh)",
                color: "#f0f3f4",
              }}
            >
              Hatanın çözüldüğünü bildirmek için onay gerekiyor.
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 5,
                background: "rgba(0,0,0, 0.33)",
                px: 3,
                py: 2,
                borderRadius: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  color: "#f0f3f4",
                  fontWeight: 600,
                  fontSize: "calc(1rem + 1vh)",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "calc(0.5rem + 1vh)",
                  }}
                >
                  Hata Başlama Zamanı
                </Typography>
                <Moment format="HH:mm:ss">{errorInfo.startedAt}</Moment>
              </Box>
              <Box sx={{}}>
                <TimePicker
                  defaultValue={
                    convertMsToTime(errorInfo.duration).digitalColock
                  }
                  useTwelveHourFormat={false}
                  showClockIcon={false}
                  onTimeChange={handleTimeChange}
                  placeholder={"00:00:00"}
                  onInputChange={undefined}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  color: "#f0f3f4",
                  fontWeight: 600,
                  fontSize: "calc(1rem + 1vh)",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "calc(0.5rem + 1vh)",
                  }}
                >
                  Hata Çözülme Zamanı
                </Typography>
                <Moment format="HH:mm:ss">{errorInfo.solvedAt}</Moment>
              </Box>
            </Box>
            <Box
              sx={{
                background: "rgba(0,0,0, 0.33)",
                px: 3,
                py: 2,
                borderRadius: 3,
              }}
            >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "1.45rem",
                        color: "#f0f3f4",
                      }}
                    >
                      Hata Kaynak Seçimi
                    </Typography>
                  </Box>
              <ToggleButtonGroup
                exclusive
                value={value}
                onChange={handleOperatorChange}
                aria-label="machine-button-toggle"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                  flexWrap: "wrap",
                }}
              >
                <ToggleButton
                  value={"GENERAL"}
                  sx={{
                    width: 144,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 172,
                      mt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          flex: 1,
                        }}
                      >
                        <BuildCircleRounded
                          sx={{
                            fontSize: "calc(1.1rem + 6.1vh)",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "calc(0.67rem + 1vh)",
                        }}
                      >
                        GENEL
                      </Typography>
                    </Box>
                  </Box>
                </ToggleButton>
                <Box
                  sx={{
                    width: 2,
                    height: 156,
                    my: "auto",
                    borderRight:
                      "1px solid rgba(255, 255, 255, 0.33) !important",
                  }}
                ></Box>
                <ToggleButton
                  value="BESLEME"
                  sx={{
                    width: 144,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 172,
                      mt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          flex: 1,
                        }}
                      >
                        <LooksOneRounded
                          sx={{
                            fontSize: "calc(1.1rem + 6.1vh)",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "calc(0.67rem + 1vh)",
                        }}
                      >
                        ŞİŞE BESLEME
                      </Typography>
                    </Box>
                  </Box>
                </ToggleButton>
                <Box
                  sx={{
                    width: 2,
                    height: 156,
                    my: "auto",
                    borderRight:
                      "1px solid rgba(255, 255, 255, 0.33) !important",
                  }}
                ></Box>
                <ToggleButton
                  value="DOLUM"
                  sx={{
                    width: 144,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 172,
                      mt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                      }}
                    >
                      <LooksTwoRounded
                        sx={{
                          fontSize: "calc(1.1rem + 6.1vh)",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "calc(0.67rem + 1vh)",
                        }}
                      >
                        DOLUM
                      </Typography>
                    </Box>
                  </Box>
                </ToggleButton>
                <Box
                  sx={{
                    width: 2,
                    marginLeft: 2,
                    height: 156,
                    my: "auto",
                    borderRight:
                      "1px solid rgba(255, 255, 255, 0.33) !important",
                  }}
                ></Box>
                <ToggleButton
                  value="ETIKET"
                  sx={{
                    width: 144,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 172,
                      mt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                      }}
                    >
                      <Looks3Rounded
                        sx={{
                          fontSize: "calc(1.1rem + 6.1vh)",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "calc(0.67rem + 1vh)",
                        }}
                      >
                        ETİKET
                      </Typography>
                    </Box>
                  </Box>
                </ToggleButton>
                <Box
                  sx={{
                    width: 2,
                    marginLeft: 2,
                    height: 156,
                    my: "auto",
                    borderRight:
                      "1px solid rgba(255, 255, 255, 0.33) !important",
                  }}
                ></Box>
                <ToggleButton
                  value="KUTULAMA"
                  sx={{
                    width: 144,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 172,
                      mt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                      }}
                    >
                      <Looks4Rounded
                        sx={{
                          fontSize: "calc(1.1rem + 6.1vh)",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "calc(0.67rem + 1vh)",
                        }}
                      >
                        KUTULAMA
                      </Typography>
                    </Box>
                  </Box>
                </ToggleButton>
                <Box
                  sx={{
                    width: 2,
                    marginLeft: 2,
                    height: 156,
                    my: "auto",
                    borderRight:
                      "1px solid rgba(255, 255, 255, 0.33) !important",
                  }}
                ></Box>
                <ToggleButton
                  value="ITS"
                  sx={{
                    width: 144,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 172,
                      mt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                      }}
                    >
                      <Looks5Rounded
                        sx={{
                          fontSize: "calc(1.1rem + 6.1vh)",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "calc(0.67rem + 1vh)",
                        }}
                      >
                        İTS
                      </Typography>
                    </Box>
                  </Box>
                </ToggleButton>
                <Box
                  sx={{
                    width: 2,
                    marginLeft: 2,
                    height: 156,
                    my: "auto",
                    borderRight:
                      "1px solid rgba(255, 255, 255, 0.33) !important",
                  }}
                ></Box>
                <ToggleButton
                  value="KOLILEME"
                  sx={{
                    width: 144,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 172,
                      mt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                      }}
                    >
                      <Looks6Rounded
                        sx={{
                          fontSize: "calc(1.1rem + 6.1vh)",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "calc(0.67rem + 1vh)",
                        }}
                      >
                        KOLİLEME
                      </Typography>
                    </Box>
                  </Box>
                </ToggleButton>
              </ToggleButtonGroup>
              <Grow
                in={errorInfo.isStarted}
                style={{
                  transformOrigin: "10 0 0",
                }}
                timeout={1000}
              >
                <Box
                  sx={{
                    mt: 3,
                    display: errorInfo.isStarted ? "block" : "none",
                    borderRadius: 2.5,
                    minWidth: 300,
                    background: "rgba(0,0,0, 0.24)",
                    py: 1,
                    px: 3,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "1.45rem",
                        color: "#f0f3f4",
                      }}
                    >
                      Hata Seçimi
                    </Typography>
                  </Box>
                  <RadioGroup
                    name="use-radio-group"
                    value={selectedErrorTitle}
                    defaultValue={selectedErrorTitle}
                    onChange={handleErrorTitleChange}
                  >
                    {selectedErrors.map((item, index) => (
                      <MyFormControlLabel
                        key={index}
                        value={item.title}
                        label={item.title}
                        control={<BpRadio />}
                      />
                    ))}
                                          <MyFormControlLabel
                        value={"Diğer"}
                        label={"Diğer"}
                        control={<BpRadio />}
                      />
                  </RadioGroup>
                </Box>
              </Grow>
              <Box
                sx={{
                  mt: 3,
                }}
              >
                <CssTextField
                  placeholder="Açıklama (Opsiyonel)"
                  label="Açıklama"
                  name="description"
                  variant="outlined"
                  value={errorDescription}
                  onChange={(e) => {
                    setErrorDescription(e.target.value);
                  }}
                  fullWidth
                  multiline
                  rows={2}
                  InputLabelProps={{
                    style: { color: "#f3f4f5", fontSize: "1.45rem" },
                  }}
                  sx={{
                    mb: 1,
                    color: "#f3f4f5",
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 3,
              mt: 3,
              maxWidth: "78%",
              mx: "auto",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                flex: 1,
                color: "#f0f3f4",
                fontSize: "1.5rem",
                height: 72,
                borderColor: "rgba(255, 255, 255, 0.36)",
              }}
              onClick={onClose}
            >
              İptal
            </Button>
            <Button
              sx={{
                flex: 1,
                fontSize: "1.5rem",
                color: "#212121",
                height: 72,
                background: "rgba(255, 255, 255, 0.67)",
              }}
              variant="contained"
              onClick={onSaveErrorInfo}
              startIcon={
                <DoneRounded
                  sx={{
                    fontSize: "2.5rem !important",
                  }}
                />
              }
            >
              Onayla
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorChangeDialog;
