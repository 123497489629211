// ** Icon Imports
import {
  DateRangeRounded,
  EventRounded,
  KeyboardArrowDown,
  PersonRounded,
} from "@mui/icons-material";

// ** MUI Imports
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import { DateRange, DateRangePicker } from "react-date-range";
import { tr } from "date-fns/locale";

// ** React Imports
import { useState } from "react";

// ** Types
type DateCustomizedFilterButtonProps = {
  dateRange: Range[];
  setDateRange: (value: React.SetStateAction<Range[]>) => void;
};

// ** Styled Components
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 7,
    backgroundColor: "rgba(0,0,0,0.5)",
    background: "rgba(255, 255, 255, 0.13) !important",
    backdropFilter: "blur(16px)",
    minWidth: 210,
    boxShadow: "0px 0px 15px 15px rgba(0,0,0,0.1)",
    "& .MuiMenu-list": {
      padding: 0,
    },
    "& .MuiMenuItem-root": {
      borderRadius: 4,
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: "rgb(236, 240, 241)",
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.secondary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface Range {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  color?: string | undefined;
  key?: string | undefined;
  autoFocus?: boolean | undefined;
  disabled?: boolean | undefined;
  showDateDisplay?: boolean | undefined;
}

const DateCustomizedFilterButton = (props: DateCustomizedFilterButtonProps) => {
  // ** Props
  const { dateRange, setDateRange } = props;

  // ** States
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  // ** Seçili menü fonksiyonu tetiklendiğinde
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // ** Menüyü kapat
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
      }}
    >
      <Button
        id="operator-customized-filter-button"
        aria-controls={open ? "operator-customized-filter-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        sx={{
          pr: 2,
          color: "rgb(236, 240, 241)",
          backgroundColor: "rgba(236, 240, 241, 0.12)",
          "&:hover": {
            backgroundColor: "rgba(236, 240, 241, 0.21)",
          },
        }}
        endIcon={<KeyboardArrowDown />}
      >
        <Box display="flex" justifyContent="flex-end">
          <Tooltip
            title={`${dateRange[0].startDate?.toLocaleDateString()} - ${dateRange[0].endDate?.toLocaleDateString()} arasındaki vardiyalar`}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                borderRadius: 1,
                px: 1,
                py: 0.5,
                border: "0.07rem solid rgba(2, 224, 164, 0.33) !important",
                color: "rgb(2, 224, 164)",
                backgroundColor: "rgba(2, 224, 164, 0.21)",
                "&:hover": {
                  backgroundColor: "rgba(2, 224, 164, 0.33)",
                },
              }}
            >
              <DateRangeRounded />
              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                  fontSize: "1rem",
                  mr: 1,
                }}
              >
                {`${dateRange[0].startDate?.toLocaleDateString()} - ${dateRange[0].endDate?.toLocaleDateString()}`}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
      </Button>
      <StyledMenu
        id="operator-customized-filter-menu"
        MenuListProps={{
          "aria-labelledby": "operator-customized-filter-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box display="flex" flexWrap="wrap" justifyContent="center">
          <Card
            sx={{
              m: 1,
            }}
          >
            <CardContent
              sx={{
                p: 1,
                pb: "0.5rem !important",
                background: "#eff2f7",
              }}
            >
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: 'auto'
                  },
                }}
              >
                <DateRange
                  onChange={(item) => setDateRange([item.selection])}
                  showDateDisplay
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={dateRange}
                  direction="horizontal"
                  locale={tr}
                  maxDate={new Date()}
                />
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "flex",
                    sm: "none",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: 'auto'
                  },
                }}
              >
                <DateRange
                  onChange={(item) => setDateRange([item.selection])}
                  months={1}
                  ranges={dateRange}
                  direction="vertical"
                  locale={tr}
                  maxDate={new Date()}
                  scroll={{ enabled: true }}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </StyledMenu>
    </Box>
  );
};

export default DateCustomizedFilterButton;
