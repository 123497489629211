import { Cancel, DateRangeRounded, PersonRounded } from "@mui/icons-material";
import { Box, Chip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { addDays } from "date-fns";

interface Range {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  color?: string | undefined;
  key?: string | undefined;
  autoFocus?: boolean | undefined;
  disabled?: boolean | undefined;
  showDateDisplay?: boolean | undefined;
}

type FiltersProps = {
  dateRange: Range[];
  setDateRange: (value: React.SetStateAction<Range[]>) => void;
  operators: string[];
  setOperators: (value: React.SetStateAction<string[]>) => void;
};

const Filters = (props: FiltersProps) => {
  const { dateRange, setDateRange, operators, setOperators } = props;

  const handleDelete = (chipToDelete: string) => () => {
    const filtered = operators.filter((item) => item !== chipToDelete);
    setOperators(filtered);
  };

  const handleDateRangeDelete = () => () => {
    setDateRange([
      {
        startDate: addDays(new Date(), -10),
        endDate: new Date(),
        key: "selection",
        color: "#191919",
      },
    ]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        flexDirection: "row",
        gap: 1,
        py: 0.5,
        px: 0.5,
        m: 0,
      }}
    >
      <Chip
        icon={
          <DateRangeRounded
            fontSize="small"
            sx={{ "&&": { color: "#f0f3f4" } }}
          />
        }
        label={`${dateRange[0].startDate?.toLocaleDateString()} - ${dateRange[0].endDate?.toLocaleDateString()}`}
        onDelete={handleDateRangeDelete()}
        deleteIcon={
          <Cancel
            sx={{
              "&&": {
                fontSize: "1.25rem",
                color: "rgba(255, 255, 255, 0.72)",
                "&:hover": { color: "#f0f3f4" },
              },
            }}
          />
        }
        sx={{
          pl: 0.5,
          py: 0.5,
          color: "#f0f3f4",
        }}
      />
      {operators.map((item, index) => {
        return (
          <Chip
            key={index}
            icon={
              <PersonRounded
                fontSize="small"
                sx={{ "&&": { color: "#f0f3f4" } }}
              />
            }
            label={item}
            onDelete={handleDelete(item)}
            deleteIcon={
              <Cancel
                sx={{
                  "&&": {
                    fontSize: "1.25rem",
                    color: "rgba(255, 255, 255, 0.72)",
                    "&:hover": { color: "#f0f3f4" },
                  },
                }}
              />
            }
            sx={{
              pl: 0.5,
              py: 0.5,
              color: "#f0f3f4",
            }}
          />
        );
      })}
    </Box>
  );
};

export default Filters;
